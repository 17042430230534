import { ArticlesGroupLayouts } from './Item/ArticlesGroup';

export function getArticleGroupLayoutByIndex(
  idx: number,
): ArticlesGroupLayouts {
  switch (idx) {
    case 0:
      return 'gridPrimary';
    case 1:
      return 'stack';
    case 2:
      return 'gridSecondary';
    default:
      return 'gridStack';
  }
}
