import { SWRConfig } from 'swr';
import { withLayout } from 'src/components/Layout';
import withGlobalData from 'src/hocs/with-global-data';
import {
  getStaticProps as _getStaticProps,
  StaticIndexDataProps,
} from 'src/data-fetching/fetch-index';
import IndexPageContainer from 'src/containers/IndexPageContainer';
import RouterFallback from 'src/components/RouterFallback';

function Home(props: StaticIndexDataProps) {
  return (
    <>
      <RouterFallback error={'error' in props}>
        <SWRConfig value={{ fallback: props.fallback }}>
          <IndexPageContainer />
        </SWRConfig>
      </RouterFallback>
    </>
  );
}

export const getStaticProps = withGlobalData(_getStaticProps);
export default withLayout(Home as any);
