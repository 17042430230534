import React from 'react';
import styled from 'styled-components';
import InfinityLoading from 'src/components/InfinityLoading';
import { useSWRInfiniteWithFallback } from 'src/hooks/use-swr-infinite-with-fallback';
import RouterFallback from 'src/components/RouterFallback';
import { getArticleGroupLayoutByIndex } from './ArticlesGroup/helpers';
import ArticlesGroup from './ArticlesGroup/Item/ArticlesGroup';
import { ArticlesApi, ArticlesGroupsResponse } from 'src/services/articles';
import { indexPageSwrKey } from 'src/constants/swr-keys';

const articlesApi = new ArticlesApi();

const IndexPageContainer: React.FC = () => {
  const { data, fetchMore, isLoading, error } =
    useSWRInfiniteWithFallback<ArticlesGroupsResponse>({
      fallbackKey: indexPageSwrKey,
      fetcher: async cursor =>
        await articlesApi.getArticleGroups(cursor, {
          shownInMainPage: 'true',
        }),
      getPaging: prevPageData => prevPageData?.paging,
    });

  const hasMore = data?.[data.length - 1].paging.hasMore;
  const listItems = data.map(d => d.items).flat();
  return (
    <RouterFallback loading={!data && !error} error={error}>
      {data && (
        <FeedCss>
          <InfinityLoading
            onFetchMore={fetchMore}
            isLoading={isLoading}
            shouldFetchMore={hasMore}
          >
            {listItems.map((ag, idx) => {
              return (
                <ArticlesGroup
                  key={`article-groupd-${idx}`}
                  layout={getArticleGroupLayoutByIndex(idx)}
                  title={ag.name}
                  items={ag.articles.items}
                  slug={ag.slug}
                />
              );
            })}
          </InfinityLoading>
        </FeedCss>
      )}
    </RouterFallback>
  );
};

export default IndexPageContainer;

const FeedCss = styled.div`
  & > section:not(:last-child) {
    margin-bottom: ${p => p.theme.sizes.spacing8};
  }
`;
